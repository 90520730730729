import { isMatch } from "underscore";

// Filter boxes for reference slider, format output array
export function getFilteredBoxes(allStrapiBoxes, boxGroup = "references") {
  let filteredBoxes = [];
  let projectId;
  switch (boxGroup) {
    case "highlighted-references":
      projectId = 1;
      filteredBoxes = filterByBoxGroupId(allStrapiBoxes, projectId);
      return formatResultsArray(filteredBoxes);

    case "city-solutions":
      projectId = 2;
      filteredBoxes = filterByBoxGroupId(allStrapiBoxes, projectId);
      return formatResultsArray(filteredBoxes);

    case "product-development":
      projectId = 3;
      filteredBoxes = filterByBoxGroupId(allStrapiBoxes, projectId);
      return formatResultsArray(filteredBoxes);

    case "business-development":
      projectId = 4;
      filteredBoxes = filterByBoxGroupId(allStrapiBoxes, projectId);
      return formatResultsArray(filteredBoxes);

    case "references":
      projectId = 5;
      filteredBoxes = filterByBoxGroupId(allStrapiBoxes, projectId);
      return formatResultsArray(filteredBoxes);

    default:
      return;
  }
}

function filterByBoxGroupId(boxesToFilter, id = 1) {
  const filteredBoxes = boxesToFilter.filter((item) => isMatch(item.box_group, { strapi_id: id }));
  return filteredBoxes;
}

function formatResultsArray(filteredBoxes) {
  let result = [];
  filteredBoxes.forEach((item) => {
    result.push({
      title: item.title,
      link: item.link,
      background: item.background !== null ? item.background.localFile.url : null,
      logo: item.logo !== null ? item.logo.localFile.url : null,
    });
  });
  return result;
}

/**
 * Takes care of modifying the urls
 * https://github.com/mhm13dev/modify-image-url-md
 *
 * @param string text
 * @param string host
 *
 * @return string
 */
export function modifyMdImageUrls(text, host) {
  /*
  text: this is the text in which there are some urls that you want to modify
  host: the hostname that you want to prepend to the urls
  */

  // Get All the images from the text
  let images = text.match(/\!\[.*\]\(.*\)/g); // eslint-disable-line

  if (images && images.length) {
    images.forEach((img) => {
      // Get the url of single image
      const url = img.match(/[\(].*[^\)]/)[0].split("(")[1]; // eslint-disable-line

      // if (!url.startsWith("http")) {
      // Modified url
      const modifiedImageUrl = `${host}${url}`;

      // replace the image url with modifiedImageUrl
      text = text.replace(url, modifiedImageUrl);
    });
  }

  // return the text that contains Modified Images Urls
  return text;
}
