import React, { useState } from "react";
import { Link } from "gatsby";

import backlinkArrow from "../../../images/svg/back_icon.svg";

import "../../../css/components/blocks/ImageBlock.scss";


/* eslint-disable */
const ImageBlock = ({
  headingText,
  backgroundImage,
  hasOverlay = true,
  textColor,
  boxHeight,
  boxLogo,
  hasBackArrow,
  linkTo,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const onHoverHandle = () => {
    setIsHovered(!isHovered);
  };

  const getClasses = () => {
    return "image-block" + (hasOverlay ? " has-overlay " : " ");
  };

  let content;
  if (linkTo) {
    content = (
      <Link to={linkTo || "/"}>
        {hasOverlay && (
          <div className="overlay-text">
            <h3>
              
                {hasBackArrow && (
                  <img className="back-arrow" src={backlinkArrow} alt="" />
                )}
                {headingText}
              
            </h3>
          </div>
        )}

        {boxLogo && <img src={boxLogo} alt="" className="box-logo" />}

        <img src={backgroundImage} alt={headingText} />
      </Link>
    );
  } else {
    content = (
      <>
        {hasOverlay && (
          <div className="overlay-text">
            <h3>
              <div onClick={() => { window.history.back(); }}>
                {hasBackArrow && <img className="back-arrow" src={backlinkArrow} alt="" />}
                {headingText}
              </div>
            </h3>
          </div>
        )}

        {boxLogo && <img src={boxLogo} alt="" className="box-logo" />}

        <img src={backgroundImage} alt={headingText} />
      </>
    );
  }

  return (
    <div
      onMouseOver={onHoverHandle}
      onFocus={onHoverHandle}
      onBlur={onHoverHandle}
      onMouseOut={onHoverHandle}
      className={
        isHovered
          ? getClasses() + "animate_hover"
          : getClasses()
      }
      style={{ color: textColor, height: boxHeight }}
    >
      {content}
    </div>
  );
};

export default ImageBlock;
