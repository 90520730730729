import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import BlocksContainer from "../BlocksContainer/BlocksContainer";
import MapView from "../MapView/MapView";
import axios from "axios";

import "../../css/components/Contact.scss";

const Contact = ({ headingText, oneMap }) => {
  const config = {
    headers: {},
  };

  const emailInput = useRef(null);
  const nameInput = useRef(null);
  const phoneInput = useRef(null);
  const topicInput = useRef(null);
  const messageInput = useRef(null);

  const honeypot1Input = useRef(null);
  const honeypot2Input = useRef(null);

  const [isSuccessfullSend, setIsSuccessfullSend] = useState(null);
  const [formSubmitTime, setFormSubmitTime] = useState(null);

  const submitContactForm = (event) => {
    event.preventDefault();

    const url = `${process.env.STRAPI_API_URL}/api/send-email`;

    // if a honeypot field is filled by a bot
    if (honeypot1Input.current.value || honeypot2Input.current.value) {
      return;
    }

    const data = {
      email: emailInput?.current?.value,
      name: nameInput?.current?.value,
      phone: phoneInput?.current?.value,
      topic: topicInput?.current?.value,
      message: messageInput?.current?.value,
    };

    const currDate = new Date();
    if (currDate.getTime() <= formSubmitTime + 5000) {
      setFormSubmitTime(new Date().getTime());
      return;
    }
    setFormSubmitTime(new Date().getTime());

    axios
      .post(url, data || {}, config)
      .then((res) => {
        setIsSuccessfullSend(true);
        return res;
      })
      .catch((err) => {
        setIsSuccessfullSend(false);
        //console.log(err);
        return;
      });
  };

  const queriedData = useStaticQuery(graphql`
    query ContactQuery {
      mapLocations: strapiMapLocation {
        office_address
        office_latitude
        office_longitude
        office_map_zoom
        premise_address
        premise_latitude
        premise_longitude
        premise_map_zoom
      }
    }
  `);

  const mapLocations = queriedData.mapLocations;

  const { office_address, office_latitude, office_longitude, office_map_zoom, premise_address, premise_latitude, premise_longitude, premise_map_zoom } = mapLocations;

  return (
    <section>
      <Container fluid className="contact-container">
        <Row>
          <Col md={12} lg={6} className="left-block d-flex align-items-md-center align-items-lg-end justify-content-md-center flex-md-column mb-md-5">
            <div className="block-content">
              <h2>Kapcsolat</h2>
              <Form className="" onSubmit={submitContactForm}>
                <Form.Group className="mb-2 input-group-1" controlId="nickname">
                  <Form.Control name="nickname" ref={nameInput} type="text" placeholder="Név..." />
                </Form.Group>

                <Form.Group className="mb-2 input-group-2" controlId="name">
                  <input autoComplete="off" type="text" name="firstname" placeholder="Your first name here" tabIndex="-1" ref={honeypot1Input}></input>
                </Form.Group>

                <Form.Group className="mb-2 input-group-3" controlId="name">
                  <input autoComplete="off" type="text" name="lastname" placeholder="Your last name here" tabIndex="-1" ref={honeypot2Input}></input>
                </Form.Group>

                <Form.Group className="mb-2 input-group-4" controlId="email">
                  <Form.Control name="email" ref={emailInput} type="email" placeholder="E-mail..." />
                </Form.Group>
                <Form.Group className="mb-2 input-group-5" controlId="tel">
                  <Form.Control name="phone" ref={phoneInput} type="tel" placeholder="Telefon..." />
                </Form.Group>

                <Form.Group className="mb-2 input-group-6" controlId="department">
                  <Form.Select name="topic" ref={topicInput} aria-label="Select department">
                    <option value="Értékesítés">Értékesítés</option>
                    <option value="Tervezés">Tervezés</option>
                    <option value="Fejlesztés">Fejlesztés</option>
                    <option value="Logisztika">Logisztika</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mt-4 input-group-7" style={{ marginBottom: "12px" }} controlId="message">
                  <Form.Control as="textarea" rows={6} name="message" ref={messageInput} placeholder="Üzenet..." />
                </Form.Group>

                <Button variant="primary" type="submit">
                  Elküld
                </Button>
                {isSuccessfullSend === true && <p className="success-notification">Sikeresen elküldve. Köszönjük a megkeresést!</p>}
                {isSuccessfullSend === false && <p className="error-notification">Az elküldés sikertelen.</p>}
              </Form>
            </div>
          </Col>
          <Col md={12} lg={6} className="right-block">
            <BlocksContainer>
              {oneMap ? (
                <div className="map-container-full">
                  <MapView title={office_address} className="map" lat={parseFloat(office_latitude)} lng={parseFloat(office_longitude)} zoom={18}></MapView>
                </div>
              ) : (
                <>
                  <div className="map-container">
                    <div className="overlay">Iroda</div>
                    <MapView title={office_address} className="map" lat={parseFloat(office_latitude)} lng={parseFloat(office_longitude)} zoom={office_map_zoom}></MapView>
                  </div>
                  <div className="map-container">
                    <div className="overlay">Telephely</div>
                    <MapView
                      title={premise_address}
                      className="map"
                      lat={parseFloat(premise_latitude)}
                      lng={parseFloat(premise_longitude)}
                      zoom={premise_map_zoom}
                      scriptsAlreadyLoaded={true}></MapView>
                  </div>
                </>
              )}
            </BlocksContainer>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
