import React from "react";

import "../../css/components/blocks/BlocksContainer.scss";

const BlocksContainer = (props) => {
  const { isFullWidth, customStyles } = props;
  return (
    <div
      className={"blocks-container " + (isFullWidth ? "is-full-width" : "")}
      style={customStyles}
    >
      {props.children}
    </div>
  );
};

export default BlocksContainer;
